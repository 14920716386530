import React, { useRef, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { gsap } from "gsap"
import { ScrollTrigger } from 'gsap/src/ScrollTrigger'
import { Row, Col } from "react-bootstrap"

const Images = ({ reference, text1, text2, text3, text4 }) => {
    const data = useStaticQuery(graphql`
    query {
      tinyCircle: file(relativePath: { eq: "tiny_circle.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      smallCircle: file(relativePath: { eq: "small_circle.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mediumCircle: file(relativePath: { eq: "medium_circle.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      largeCircle: file(relativePath: { eq: "large_circle.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
    return (
        <Row ref={reference[0]} className="align-items-end" style={{ width: `100vw`, textAlign: `center` }}>
            <Col sm="12" md="3" ref={reference[1]}>
                <Img style={{ width: `40px`, margin: `auto`, marginBottom: `4rem` }}
                    fluid={data.tinyCircle.childImageSharp.fluid} alt="Tiny size circle" />
                <p>{text1}</p>
            </Col>
            <Col sm="12" md="3" ref={reference[2]}>
                <Img style={{ width: `70px`, margin: `auto`, marginBottom: `2rem` }}
                    fluid={data.smallCircle.childImageSharp.fluid} alt="Small size circle" />
                <p>{text2}</p>
            </Col>
            <Col sm="12" md="3" ref={reference[3]}>
                <Img style={{ width: `100px`, margin: `auto`, marginBottom: `2rem` }}
                    fluid={data.mediumCircle.childImageSharp.fluid} alt="Medium size circle" />
                <p>{text3}</p>
            </Col>
            <Col sm="12" md="3" ref={reference[4]}>
                <Img style={{ width: `130px`, margin: `auto`, marginBottom: `2rem` }}
                    fluid={data.largeCircle.childImageSharp.fluid} alt="Large size circle" />
                <p>{text4}</p>
            </Col>
        </Row>
    )
}

const Circles = ({ text1, text2, text3, text4 }) => {
    const containerRef = useRef(null);
    const tinycircleRef = useRef(null);
    const smallcircleRef = useRef(null);
    const mediumcircleRef = useRef(null);
    const largecircleRef = useRef(null);

    useEffect(() => {

        gsap.registerPlugin(ScrollTrigger);

        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: containerRef.current,
                start: "0px, 50%",
                toggleActions: "restart none none reverse"
            }
        })
            tl.from(tinycircleRef.current, {
                duration: 0.4,
                autoAlpha: 0
            }, "<1")

            tl.from(smallcircleRef.current, {
                duration: 0.8,
                autoAlpha: 0
            }, "<1")

            tl.from(mediumcircleRef.current, {
                duration: 1.2,
                autoAlpha: 0
            }, "<1")

            tl.from(largecircleRef.current, {
                duration: 1.6,
                autoAlpha: 0
            }, "<1")
    })
    return (
        <Images
            reference={[containerRef, tinycircleRef, smallcircleRef, mediumcircleRef, largecircleRef]}
            text1={text1}
            text2={text2}
            text3={text3}
            text4={text4}
        />
    )
}

export default Circles
import React from "react"
import Styled from "styled-components"

const FormSections = Styled.div`
margin: auto;
padding-left: 30px;
padding-right: 30px;
@media(min-width: 768px){
    width: 860px;
}
@media(max-width: 768px){
    width: 100%;
}
`

export default () =>
    <div style={{ width: `100vw` }}>
        <FormSections>
            <h2 style={{ fontSize: `3rem` }}>LET'S TALK</h2>
        </FormSections>
        <iframe title="HAG Ventures Contact Form" frameBorder="0" style={{ height: `700px`, width: `100%`, border: `none ` }} 
        src='https://forms.zohopublic.com/haggroup/form/Websites/formperma/XWOFn3eA_13k0BTCGUZiWHjmM04S509FhoxBwZ39wJs'>
        </iframe>
    </div>
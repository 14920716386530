import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const RodEIA = ({ style }) => {
  const data = useStaticQuery(graphql`
    query {
      rodEIA: file(relativePath: { eq: "Rod_EIA.png" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `)
  return <Img style={style}
    fluid={data.rodEIA.childImageSharp.fluid} alt="Rodrigo de Alvarenga putting his hands in the heart"
  />
}

export default RodEIA
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Row, Col } from "react-bootstrap"

const Education = () => {
  const data = useStaticQuery(graphql`
    query {
      pucpr: file(relativePath: { eq: "keynote-speaker/pucpr.png" }) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      ufpr: file(relativePath: { eq: "education/ufpr.png" }) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      bsp: file(relativePath: { eq: "education/bsp.png" }) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      unip: file(relativePath: { eq: "education/unip.png" }) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      bostonCollege: file(relativePath: { eq: "education/boston_college.png" }) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      rotman: file(relativePath: { eq: "education/rotman.png" }) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      singularity: file(relativePath: { eq: "education/singularity.png" }) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      fgv: file(relativePath: { eq: "education/fgv.png" }) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `)
  return (
    <div>
      <Row className="align-items-center">
        <Col md="3" sm="12">
          <Img style={{ width: `10em`, margin: `auto` }} fluid={data.pucpr.childImageSharp.fluid} alt="PUCPR Logo" />
        </Col>
        <Col md="3" sm="12">
          <Img style={{ width: `10em`, margin: `auto` }} fluid={data.ufpr.childImageSharp.fluid} alt="UFPR Logo" />
        </Col>
        <Col md="3" sm="12">
          <Img style={{ width: `10em`, margin: `auto` }} fluid={data.bsp.childImageSharp.fluid} alt="Business School São Paulo Logo" />
        </Col>
        <Col md="3" sm="12">
          <Img style={{ width: `10em`, margin: `auto` }} fluid={data.unip.childImageSharp.fluid} alt="UNIP Logo" />
        </Col>
      </Row>
      <Row style={{ marginTop: `4em` }} className="align-items-center">
        <Col md="3" sm="12">
          <Img style={{ width: `10em`, margin: `auto` }} fluid={data.bostonCollege.childImageSharp.fluid} alt="Boston College Logo" />
        </Col>
        <Col md="3" sm="12">
          <Img style={{ width: `10em`, margin: `auto` }} fluid={data.rotman.childImageSharp.fluid} alt="Rotman School of Management Logo" />
        </Col>
        <Col md="3" sm="12">
          <Img style={{ width: `10em`, margin: `auto` }} fluid={data.singularity.childImageSharp.fluid} alt="Singularity University Logo" />
        </Col>
        <Col md="3" sm="12">
          <Img style={{ width: `10em`, margin: `auto` }} fluid={data.fgv.childImageSharp.fluid} alt="FGV Logo" />
        </Col>
      </Row>
    </div>
  )
}

export default Education
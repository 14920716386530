import React, { useRef, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { gsap } from "gsap"
import { ScrollTrigger } from 'gsap/src/ScrollTrigger'
import { Row, Col } from "react-bootstrap"

const Images = ({ reference }) => {
  const data = useStaticQuery(graphql`
    query {
      mit: file(relativePath: { eq: "mentor/mit.png" }) {
        childImageSharp {
          fluid(maxWidth: 420) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      eia: file(relativePath: { eq: "mentor/eia.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      startupGrind: file(relativePath: { eq: "mentor/startup_grind.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      gsa: file(relativePath: { eq: "mentor/gsa.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      alchemist: file(relativePath: { eq: "mentor/alchemist.png" }) {
        childImageSharp {
          fluid(maxWidth: 420) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      aia: file(relativePath: { eq: "mentor/aia.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sup: file(relativePath: { eq: "mentor/sup.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      founder: file(relativePath: { eq: "mentor/founder.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <div ref={reference[0]}>
      <Row className="align-items-center">
        <Col md="3" sm="12" ref={reference[1]}>
          <Img style={{ width: `10em`, margin: `auto` }} fluid={data.mit.childImageSharp.fluid} alt="MIT Innovators Under 35 Logo" />
        </Col>
        <Col md="3" sm="12" ref={reference[2]}>
          <Img style={{ width: `10em`, margin: `auto` }} fluid={data.eia.childImageSharp.fluid} alt="European Innovation Academy Logo" />
        </Col>
        <Col md="3" sm="12" ref={reference[3]}>
          <Img style={{ width: `10em`, margin: `auto` }} fluid={data.startupGrind.childImageSharp.fluid} alt="Startup Grind Logo" />
        </Col>
        <Col md="3" sm="12" ref={reference[4]}>
          <Img style={{ width: `10em`, margin: `auto` }} fluid={data.gsa.childImageSharp.fluid} alt="Global Scalling Academy Logo" />
        </Col>
      </Row>
      <Row style={{ marginTop: `4em` }} className="align-items-center">
        <Col md="3" sm="12" ref={reference[5]}>
          <Img style={{ width: `10em`, margin: `auto` }} fluid={data.alchemist.childImageSharp.fluid} alt="Alchemist Accelerator Logo" />
        </Col>
        <Col md="3" sm="12" ref={reference[6]}>
          <Img style={{ width: `10em`, margin: `auto` }} fluid={data.aia.childImageSharp.fluid} alt="Arab Innovation Academy Logo" />
        </Col>
        <Col md="3" sm="12" ref={reference[7]}>
          <Img style={{ width: `10em`, margin: `auto` }} fluid={data.sup.childImageSharp.fluid} alt="Start-Up Chile Logo" />
        </Col>
        <Col md="3" sm="12" ref={reference[8]}>
          <Img style={{ width: `10em`, margin: `auto` }} fluid={data.founder.childImageSharp.fluid} alt="Founder Institute Logo" />
        </Col>
      </Row>
    </div>
  )
}

const Mentor = ({ text }) => {
  const containerRef = useRef(null);
  const mitRef = useRef(null);
  const eiaRef = useRef(null);
  const startupgrindRef = useRef(null);
  const gsaRef = useRef(null);
  const alchemistRef = useRef(null);
  const aiaRef = useRef(null);
  const supRef = useRef(null);
  const founderRef = useRef(null);

  useEffect(() => {

    gsap.registerPlugin(ScrollTrigger);

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: containerRef.current,
        start: "0px, 50%",
        toggleActions: "restart none none reverse"
      }
    })
      tl.from(mitRef.current, {
        duration: 0.4,
        autoAlpha: 0
      }, "<")

      tl.from(eiaRef.current, {
        duration: 0.8,
        autoAlpha: 0
      }, "<")

      tl.from(startupgrindRef.current, {
        duration: 1.2,
        autoAlpha: 0
      }, "<")

      tl.from(gsaRef.current, {
        duration: 1.6,
        autoAlpha: 0
      }, "<")

      tl.from(alchemistRef.current, {
        duration: 2,
        autoAlpha: 0
      }, "<")

      tl.from(aiaRef.current, {
        duration: 2.4,
        autoAlpha: 0
      }, "<")

      tl.from(supRef.current, {
        duration: 2.8,
        autoAlpha: 0
      }, "<")

      tl.from(founderRef.current, {
        duration: 3.2,
        autoAlpha: 0
      }, "<")
  })
  return (
    <Images
      reference={[containerRef, mitRef, eiaRef, startupgrindRef, gsaRef,
        alchemistRef, aiaRef, supRef, founderRef]}
      text={text}
    />
  )
}

export default Mentor
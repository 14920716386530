import React from "react"
import Layout from "../components/layout"
import Menu from "../components/menu"
import Styled from "styled-components"
import { Row, Col } from "react-bootstrap"
import Body from "../components/body"
import CurveLine from "../components/images/curve_line"
import Name from "../components/name"
import WhitePills from "../components/white_pills"
import QuestionMark from "../components/images/question_mark"
import Quote from "../components/quote"
import RodEIA from "../components/images/RodEIA"
import Globe from '../components/images/globe'
import Glasses from "../components/images/glasses"
import Cascade from "../components/lottie-components/cascade"
import Section from "../components/section"
import BlackSection from "../components/blacksection"
import RocketLine from "../components/rocketline"
import Experience from "../components/images/experience"
import CurlyLine from "../components/lottie-components/curly_line"
import CascadeRotated from "../components/lottie-components/cascade_rotated"
import Map from "../components/images/map"
import Mentor from "../components/images/mentor"
import Laurels from "../components/images/laurels"
import Clouds from "../components/clouds"
import KeynoteSpeaker from "../components/images/keynote_speaker"
import Topics from "../components/topics"
import Education from "../components/images/education"
import ClassFilter from "../components/images/class_filter"
import Highlight from "../components/highlight"
import Form from "../components/form"
import Footer from "../components/footer"
import QuoteName from "../components/quote_name"
import Circles from "../components/images/circles"
import LazyLoad from "react-lazyload"

const ReponsiveQuestionMark = Styled.div`
position: absolute;
margin-top: -20em;
@media(max-width: 900px){
  margin-left: 25vw;
}
@media(min-width: 900px){
  margin-left: 70vw;
}
`

const FormattedRow = Styled(Row)`
width: 100vw;
padding: 10vw;
`

const QuoteContainer = Styled.div`
text-align: left;
width: 70%;
margin: auto;
padding-right: 10vw;
padding-left: 10vw;
padding-bottom: 5vw;
.phrase{
  font-family: Didot;
  font-size: 1.62671rem;
  font-weight: bold;
}
p{
  font-family: Didot;
}
`

const Didot = Styled.div`
p{
  font-family: Didot;
}
`

const IndexPage = () => (
  <Layout style={{ backgroundColor: `black`, color: `white` }}
    lang="en" title="Rodrigo de Alvarenga" link="https://www.rodrigodealvarenga.com" description="Rodrigo de Alvarenga is a venture builder, board member, advisor, mentor, speaker & professor expert in business transformation, innovation, startups & impact.">
    <Menu Titles={["ABOUT", "PROFESSIONAL", "MENTORSHIP", "SPEAKER", "ACADEMICS", "GET IN TOUCH"]} />
    <Body>
      <section className="section1">
        <CurveLine >
          <h1>
            <span style={{ fontSize: `4.5rem` }}>RODRIGO</span> <br />
            <span style={{ fontWeight: `normal`, fontSize: `2.55rem` }}>DE ALVARENGA</span>
          </h1>
        </CurveLine>
        <Row style={{ width: `100vw`, paddingBottom: `10em` }} className="justify-content-center">
          <Col sm="12">
            <p style={{ fontSize: `1.5em`, textAlign: `center` }}>WHO IS</p>
            <Name />
            <ReponsiveQuestionMark>
              <LazyLoad offset={100}>
                <QuestionMark />
              </LazyLoad>
            </ReponsiveQuestionMark>
          </Col>
        </Row>
        <div style={{ width: `100vw` }}>
          <Quote />
          <QuoteContainer>
            <p className="phrase">
              WHAT IS RIGHT IS RIGHT, WHATS IS WRONG IS WRONG AND NEITHER ONE CHANGES PLACES, ONLY WE DO.
          </p>
            <LazyLoad offset={100}>
              <QuoteName>
                <p>- RODRIGO DE ALVARENGA</p>
              </QuoteName>
            </LazyLoad>
          </QuoteContainer>
        </div>
        <FormattedRow>
          <Col sm="12" md="6">
            <RodEIA />
          </Col>
          <Col sm="12" md="6">
            <Name />
            <Didot>
              <p>
                Venture builder, angel investor, entrepreneur, consultant, mentor and speaker.
            </p>
              <p>
                Rodrigo de Alvarenga has assumed all these key roles through the years, fuelled by his passion for leadership, start-ups, and tech and social based entrepreneurship.
            </p>
              <p>
                Rodrigo believes that people are the singular factor that makes any business endeavour successful and one of a kind.
                This belief translates into Rodrigo’s role as the founder and CEO of the HAG Group, where people are put at the centre of innovative strategies for business transformation.
            </p>
            </Didot>
          </Col>
        </FormattedRow>
        <FormattedRow>
          <Col sm="12" md="6">
            <Name />
            <p style={{ fontFamily: `Didot` }}>
              Besides founding and investing in start-ups and helping companies break into the global market, Rodrigo participates in international mentorship  programs.
            </p>
          </Col>
          <Col sm="12" md="6" style={{ margin: `auto` }}>
            <LazyLoad offset={100}>
              <Globe />
            </LazyLoad>
          </Col>
          <Col sm="12">
            <Didot>
              <p style={{ fontFamily: `Didot` }}>
                He is passionate about sharing the development of disruptive ideas and his knowledge and expertise with a global audience of aspiring entrepreneurs.
                His input and advice are informed by his extensive experience in the field of business and digital transformation.
              </p>
              <p>
                Himself a lifelong learner, Rodrigo is currently pursuing a PhD in Production Engineering and Systems, with a focus on venture building.
              </p>
            </Didot>
          </Col>
        </FormattedRow>
        <CurveLine>
          <Name />
          <h2>AN IN-DEPTH LOOK</h2>
        </CurveLine>
        <LazyLoad offset={100}>
          <Glasses>
            <BlackSection title="AN IN-DEPTH LOOK">
              <Didot>
                <p style={{ color: `black` }}>
                  Currently based in Curitiba, Rodrigo de Alvarenga has 22 years’ worth of experience in the financial sector, particularly in the Brazilian market.
              </p>
                <p style={{ color: `black` }}>
                  While working for various reputable banking institutions, Rodrigo collaborated with local and foreign companies, subsidiaries, shareholders, CEOs and board members.
              </p>
              </Didot>
            </BlackSection>
          </Glasses>
        </LazyLoad>
        <Cascade />
        <Section title="AN IN-DEPTH LOOK">
          <Didot>
            <p>
              After leaving the banking sector, Rodrigo dedicated himself to fulfilling his lifelong dream of founding a management consulting company that provides financial and strategic consultancy services.
          </p>
            <p>
              This became HAG Consulting, of which he is also the CEO.
          </p>
            <p>
              During this time, he also founded Eco Innovare, a start-up focused on innovations in environmental and energy efficiency.
          </p>
          </Didot>
        </Section>
        <Section title="AN IN-DEPTH LOOK">
          <Didot>
            <p>
              Since then, he has been helping people realise their dreams through mentoring and supporting start-ups and entrepreneurs. He founded Brazil’s Angels chapter in Paraná and became a Grow Ventures partner. As an angel investor, he has consistently provided financial capital to small start-ups.
            <br />
            Let’s talk about:
          </p>
            <LazyLoad offset={100}>
              <WhitePills
                text={["mentoring", "advisor services", "ventures building", "negotiation",
                  "board member experience", "innovation & technology", "business transformation",
                  "consulting services", "workshops & training", "keynote & talks", "human capital",
                  "business transformation", "impact measurement", "internationalization"]}
              />
            </LazyLoad>
          </Didot>
        </Section>
      </section>
      <section id="section2">
        <CurveLine>
          <Name />
          <h2>PROFESSIONAL EXPERIENCE</h2>
        </CurveLine>
        <Section title="PROFESSIONAL EXPERIENCE">
          <Didot>
            <p>Rodrigo’s professional experience in the financial sector spans 22 years.</p>
            <p>
              During that time, he has held various executive management positions and led
              high-performance teams and offices.
            </p>
          </Didot>
        </Section>
        <LazyLoad offset={100}>
          <RocketLine />
        </LazyLoad>
        <Section>
          <Didot>
            <p>After working for banking institutions like HSBC, Safra and Santander, Rodrigo founded the HAG Group in 2010.</p>
            <p>At HAG, he and his highly qualified team specialise in building, accelerating and scaling companies as well as tech-based and impact-driven start-ups.</p>
          </Didot>
          <LazyLoad offset={100}>
            <Experience text="EXECUTIVE BOARD MEMBER" />
          </LazyLoad>
        </Section>
      </section>
      <section id="section3">
        <CurveLine>
          <Name />
          <h2>MENTOR, JUDGE <br /> AND EVALUATOR</h2>
        </CurveLine>
        <Section title={<span>MENTOR, JUDGE <br /> AND EVALUATOR</span>}>
          <p>Rodrigo has played pivotal mentorship roles in several programs in the US, Europe, Asia, Middle East and Latin America.
          He has also served as a judge for several awards and acceleration programs both in Brazil and internationally.</p>
          <LazyLoad offset={100}>
            <Highlight>
              <p>
                Since 2017, Rodrigo has been serving as Chief Mentor for the Extreme Entrepreneurship Program at the European and Arab Innovation Academy.
                He utilises an educational methodology developed in partnership with professionals from renowned institutions like
              <span className="text-highlight"> Stanford University, U.C. Berkeley and Google.</span>
              </p>
            </Highlight>
          </LazyLoad>
        </Section>
        <div style={{ height: `100vh` }}>
          <CurlyLine />
        </div>
        <CascadeRotated />
        <div style={{ backgroundColor: `rgb(252, 252, 252)`, width: `100vw` }}>
          <LazyLoad offset={100}>
            <Map>
              <BlackSection title={<span>MENTOR, JUDGE <br /> AND EVALUATOR</span>}
                customwidth={{ width: `100%` }}>
                <p style={{ color: `black` }}>
                  <b>
                    He also serves as a Chief Mentor and Evaluator for Start-Up Chile (SUC), the leading acceleration program in Latin America.
                </b>
                </p>
                <p style={{ color: `black` }}>
                  <b>
                    Created by the Chilean government, this public start-up accelerator has aided more than 1,616 companies through its acceleration process.
                </b>
                </p>
                <p style={{ color: `black` }}>
                  <b>
                    He is also the Chief Mentor for Area 2071, a program created by the United
                    Arab Emirates’ government to develop and nurture local and international talents.
                </b>
                </p>
              </BlackSection>
            </Map>
          </LazyLoad>
        </div>
        <Section title={<span>MENTOR, JUDGE <br /> AND EVALUATOR</span>}>
          <p>
            Rodrigo also mentors for Singularity University Ventures, an innovation and entrepreneurship program by Singularity University.
          </p>
          <p>
            He has served as a judge and evaluator for the MIT Innovators Under 35 Award, which recognises exceptional innovators under 35 years old.
        </p>
          <LazyLoad offset={100}>
            <Mentor />
          </LazyLoad>
        </Section>
        <FormattedRow>
          <Col sm="12" md="6">
            <Name />
            <p>Rodrigo himself has been the recipient of various awards like the:</p>
            <p>- Aster Award for Professional Trajectory </p>
            <p>- First Place for Best Start-up Idea </p>
            <p>- HSBC Excellence Award</p>
          </Col>
          <Col sm="12" md="6">
            <LazyLoad offset={100}>
              <Laurels text="AWARDS" />
            </LazyLoad>
          </Col>
        </FormattedRow>
      </section>
      <section id="section4">
        <CurveLine>
          <Name />
          <h2>
            SPEAKING ENGAMENTS &<br /> MENTORSHIP PROGRAMS
        </h2>
        </CurveLine>
        <div style={{ width: `100vw` }}>
          <Quote />
          <QuoteContainer>
            <p className="phrase">
              What you do speaks so loudly that I cannot hear what you say.
            </p>
            <LazyLoad offset={100}>
              <QuoteName>
                <p>- RALPH WALDO EMERSON</p>
              </QuoteName>
            </LazyLoad>
          </QuoteContainer>
        </div>
        <LazyLoad offset={100}>
          <Clouds>
            <Section title="KEYNOTE AND EVENT SPEAKER">
              <p>
                Backed by his extensive experience and track record of success, Rodrigo’s passion for learning and helping people build their <u>dreams</u> makes him one of the most sought-after keynote and events speakers around.
          </p>
            </Section>
          </Clouds>
        </LazyLoad>
        <Section title="KEYNOTE AND EVENT SPEAKER">
          <p>
            He has been invited to speak at events like the 9th Global Venture Lab Summit at U.C. Berkeley (2018) and the Start-up Grind Global Conference in Silicon Valley (2019).
        </p>
          <p>
            He specialises in topics covering entrepreneurship, entrepreneurial education, venture building, start-ups, impact measurement, ecosystem building and angel investing.
        </p>
          <LazyLoad offset={100}>
            <KeynoteSpeaker />
          </LazyLoad>
        </Section>
      </section>
      <section id="section5">
        <CurveLine>
          <Name />
          <h2>ACADEMIC BACKGROUND</h2>
        </CurveLine>
        <LazyLoad offset={100}>
          <Topics
            title="ACADEMIC BACKGROUND"
            maintext="An avid learner, Rodrigo has multiple degrees with various specialities, and is currently pursuing a PhD in Production Engineering and Systems."
            text1={<p>BA in <br /> <b style={{ fontFamily: `Didot` }}>Business</b></p>}
            text2={<p>Masters of <br /> <b style={{ fontFamily: `Didot` }}>Business</b> <br /> Administration</p>}
            text3={<p>MSc in <br /> <b style={{ fontFamily: `Didot` }}>Economic</b> <br /> Development</p>}
            text4={<p>PhD in <br /> <b style={{ fontFamily: `Didot` }}>Product</b> <br /> Engineering</p>}
          />
        </LazyLoad>
        <Section title="EDUCATION">
          <Education />
        </Section>
        <LazyLoad offset={100}>
          <ClassFilter>
            <Section title="ACADEMIC BACKGROUND">
              <p>
                Besides being a PhD student, Rodrigo is also an academic mentor.
          </p>
              <p>
                He is currently a visiting professor of graduate programs at FAE Business School, teaching Impact Measurement for the
                school’s Social Entrepreneurship and Business Program.
          </p>
              <p>
                Recently, he also designed and launched a new MBA
                program, Global Entrepreneurship Experience (GEX).
          </p>
            </Section>
          </ClassFilter>
        </LazyLoad>
        <Section>
          <p>
            Rodrigo was also an assistant professor of Political Science and Economics at the Pontifical Catholic University of Paraná’s (PUC-PR) Communication and Arts School.
        </p>
          <p>
            As an active learner, he conducts research on entrepreneurial economics, impact measurement for:
        </p>
        </Section>
        <LazyLoad offset={100}>
          <Circles
            text1={<span style={{ fontFamily: `Didot` }}>startup-ups</span>}
            text2={<span style={{ fontFamily: `Didot` }}>social & tech <br /> entrepreneurship</span>}
            text3={<span style={{ fontFamily: `Didot` }}>impact <br /> investing</span>}
            text4={<span style={{ fontFamily: `Didot` }}>venture <br /> capital</span>}
          />
        </LazyLoad>
      </section>
      <section id="section6" style={{ marginTop: `4em` }}>
        <Form />
      </section>
      <Footer />
    </Body>
  </Layout >
)

export default IndexPage

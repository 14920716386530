import React, { useRef, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { gsap } from "gsap"
import { ScrollTrigger } from 'gsap/src/ScrollTrigger'
import { Row, Col } from "react-bootstrap"

const Images = ({ reference }) => {
  const data = useStaticQuery(graphql`
    query {
        sutardja: file(relativePath: { eq: "keynote-speaker/sutardja.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      startupGrind: file(relativePath: { eq: "mentor/startup_grind.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      aarhus: file(relativePath: { eq: "keynote-speaker/aarhus.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      orbitLab: file(relativePath: { eq: "keynote-speaker/orbit_lab.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      lisbonSummit: file(relativePath: { eq: "keynote-speaker/lisbon_summit.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      eia: file(relativePath: { eq: "mentor/eia.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      uniPilsen: file(relativePath: { eq: "keynote-speaker/uni_pilsen.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sitPort: file(relativePath: { eq: "keynote-speaker/sit_port.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      startupAcceleration: file(relativePath: { eq: "keynote-speaker/startup_acceleration.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      hackquarters: file(relativePath: { eq: "keynote-speaker/hackquarters.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sgTaiwan: file(relativePath: { eq: "keynote-speaker/sg_taiwan.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      angelClub: file(relativePath: { eq: "keynote-speaker/angel_club.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      founder: file(relativePath: { eq: "mentor/founder.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      inovativa: file(relativePath: { eq: "keynote-speaker/inovativa.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      pucpr: file(relativePath: { eq: "keynote-speaker/pucpr.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      amcham: file(relativePath: { eq: "keynote-speaker/amcham.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <div ref={reference[0]}>
      <Row className="align-items-center">
        <Col md="3" sm="12" ref={reference[1]}>
          <Img style={{ width: `10em`, margin: `auto` }} fluid={data.sutardja.childImageSharp.fluid} alt="Sutardja Center Logo" />
        </Col>
        <Col md="3" sm="12" ref={reference[2]}>
          <Img style={{ width: `10em`, margin: `auto` }} fluid={data.startupGrind.childImageSharp.fluid} alt="Startup Grind Logo" />
        </Col>
        <Col md="3" sm="12" ref={reference[3]}>
          <Img style={{ width: `10em`, margin: `auto` }} fluid={data.aarhus.childImageSharp.fluid} alt="AARHUS University Logo" />
        </Col>
        <Col md="3" sm="12" ref={reference[4]}>
          <Img style={{ width: `10em`, margin: `auto` }} fluid={data.orbitLab.childImageSharp.fluid} alt="Orbit Lab Logo" />
        </Col>
      </Row>
      <Row style={{ marginTop: `4em` }} className="align-items-center">
        <Col md="3" sm="12" ref={reference[5]}>
          <Img style={{ width: `10em`, margin: `auto` }} fluid={data.lisbonSummit.childImageSharp.fluid} alt="Lisbon Investment Summit Logo" />
        </Col>
        <Col md="3" sm="12" ref={reference[6]}>
          <Img style={{ width: `10em`, margin: `auto` }} fluid={data.eia.childImageSharp.fluid} alt="European Innovation Academy Logo" />
        </Col>
        <Col md="3" sm="12" ref={reference[7]}>
          <Img style={{ width: `10em`, margin: `auto` }} fluid={data.uniPilsen.childImageSharp.fluid} alt="University of West Bohemia Logo" />
        </Col>
        <Col md="3" sm="12" ref={reference[8]}>
          <Img style={{ width: `10em`, margin: `auto` }} fluid={data.sitPort.childImageSharp.fluid} alt="SIT Port Logo" />
        </Col>
      </Row>
      <Row style={{ marginTop: `4em` }} className="align-items-center">
        <Col md="3" sm="12" ref={reference[9]}>
          <Img style={{ width: `10em`, margin: `auto` }} fluid={data.startupAcceleration.childImageSharp.fluid} alt="Startup Acceleration Logo" />
        </Col>
        <Col md="3" sm="12" ref={reference[10]}>
          <Img style={{ width: `10em`, margin: `auto` }} fluid={data.hackquarters.childImageSharp.fluid} alt="Hackquarters Logo" />
        </Col>
        <Col md="3" sm="12" ref={reference[11]}>
          <Img style={{ width: `10em`, margin: `auto` }} fluid={data.sgTaiwan.childImageSharp.fluid} alt="Startup Grind Taiwan Logo" />
        </Col>
        <Col md="3" sm="12" ref={reference[12]}>
          <Img style={{ width: `10em`, margin: `auto` }} fluid={data.angelClub.childImageSharp.fluid} alt="The Angel Club Logo" />
        </Col>
      </Row>
      <Row style={{ marginTop: `4em` }} className="align-items-center">
        <Col md="3" sm="12" ref={reference[13]}>
          <Img style={{ width: `10em`, margin: `auto` }} fluid={data.founder.childImageSharp.fluid} alt="Founder Institute Logo" />
        </Col>
        <Col md="3" sm="12" ref={reference[14]}>
          <Img style={{ width: `10em`, margin: `auto` }} fluid={data.inovativa.childImageSharp.fluid} alt="Inovativa Brasil Logo" />
        </Col>
        <Col md="3" sm="12" ref={reference[15]}>
          <Img style={{ width: `10em`, margin: `auto` }} fluid={data.pucpr.childImageSharp.fluid} alt="PUCPR Logo" />
        </Col>
        <Col md="3" sm="12" ref={reference[16]}>
          <Img style={{ width: `10em`, margin: `auto` }} fluid={data.amcham.childImageSharp.fluid} alt="AMCHAM Brasil Logo" />
        </Col>
      </Row>
    </div>
  )
}

const KeynoteSpeaker = ({ text }) => {
  const containerRef = useRef(null);
  const sutardjaRef = useRef(null);
  const startupgrindRef = useRef(null);
  const aarhusRef = useRef(null);
  const orbitlabRef = useRef(null);
  const lisbonsummitRef = useRef(null);
  const eiaRef = useRef(null);
  const unipilsenRef = useRef(null);
  const sitportRef = useRef(null);
  const startupaccelerationRef = useRef(null);
  const hackquartersRef = useRef(null);
  const sgtaiwanRef = useRef(null);
  const angelclubRef = useRef(null);
  const founderRef = useRef(null);
  const inovativaRef = useRef(null);
  const pucprRef = useRef(null);
  const amchamRef = useRef(null);

  useEffect(() => {

    gsap.registerPlugin(ScrollTrigger);

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: containerRef.current,
        start: "0px, 50%",
        toggleActions: "restart none none reverse"
      }
    })
      tl.from(sutardjaRef.current, {
        duration: 0.4,
        autoAlpha: 0
      }, "<")

      tl.from(startupgrindRef.current, {
        duration: 0.8,
        autoAlpha: 0
      }, "<")

      tl.from(aarhusRef.current, {
        duration: 1.2,
        autoAlpha: 0
      }, "<")

      tl.from(orbitlabRef.current, {
        duration: 1.6,
        autoAlpha: 0
      }, "<")

      tl.from(lisbonsummitRef.current, {
        duration: 2,
        autoAlpha: 0
      }, "<")

      tl.from(eiaRef.current, {
        duration: 2.4,
        autoAlpha: 0
      }, "<")

      tl.from(unipilsenRef.current, {
        duration: 2.8,
        autoAlpha: 0
      }, "<")

      tl.from(sitportRef.current, {
        duration: 3.2,
        autoAlpha: 0
      }, "<")

      tl.from(startupaccelerationRef.current, {
        duration: 3.6,
        autoAlpha: 0
      }, "<")

      tl.from(hackquartersRef.current, {
        duration: 4,
        autoAlpha: 0
      }, "<")

      tl.from(sgtaiwanRef.current, {
        duration: 4.4,
        autoAlpha: 0
      }, "<")

      tl.from(angelclubRef.current, {
        duration: 4.8,
        autoAlpha: 0
      }, "<")

      tl.from(founderRef.current, {
        duration: 5.2,
        autoAlpha: 0
      }, "<")

      tl.from(inovativaRef.current, {
        duration: 5.6,
        autoAlpha: 0
      }, "<")

      tl.from(pucprRef.current, {
        duration: 6,
        autoAlpha: 0
      }, "<")

      tl.from(amchamRef.current, {
        duration: 6.4,
        autoAlpha: 0
      }, "<")
  })
  return (
    <Images
      reference={[containerRef, sutardjaRef, startupgrindRef, aarhusRef, orbitlabRef,
        lisbonsummitRef, eiaRef, unipilsenRef, sitportRef, startupaccelerationRef,
        hackquartersRef, sgtaiwanRef, angelclubRef, founderRef, inovativaRef,
        pucprRef, amchamRef]}
      text={text}
    />
  )
}

export default KeynoteSpeaker
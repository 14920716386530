import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import { TweenLite, gsap } from "gsap"
import { ScrollTrigger } from 'gsap/src/ScrollTrigger'

const ClassFilter = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
        class: file(relativePath: { eq: "class.png" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        filter: file(relativePath: { eq: "filter.png" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `)

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    TweenLite.from(".filter", {
      scrollTrigger: {
        trigger: ".filter",
        start: '100px, 50%',
        toggleActions: "restart none none reverse"
      },
      autoAlpha: 0,
      duration: 2
    });
  })

  return (
    <BackgroundImage
      Tag="section"
      fluid={data.class.childImageSharp.fluid}
      style={{
        minHeight: `100vh`,
        width: `100vw`,
        position: `relative`,
        backgroundPosition: `right center`
      }}
    >
      <BackgroundImage
        className="filter"
        Tag="section"
        fluid={data.filter.childImageSharp.fluid}
        style={{
          minHeight: `inherit`,
          width: `inherit`,
          position: `relative`,
          backgroundPosition: `right center`
        }}
      >
        <div style={{ position: `relative` }}>
          {children}
        </div>
      </BackgroundImage>
    </BackgroundImage>
  )
}

export default ClassFilter
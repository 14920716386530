import React, { useRef, useEffect } from "react"
import { gsap } from "gsap"
import { ScrollTrigger } from 'gsap/src/ScrollTrigger'

const RocketLine = () => {
  const rocketRef = useRef(null);
  const circleRef = useRef(null);
  const containerRef = useRef(null);
  
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: containerRef.current,
        start: "0px, 90%",
        toggleActions: "restart none none reverse"
      }
    })
      tl.from(rocketRef.current, {
        duration: 0.5,
        x: "-20vw"
      }, "<")

      tl.from(circleRef.current, {
        duration: 1,
        x: "-20vw"
      }, "<")

  })

  return (
    <div style={{ width: `100vw` }} ref={containerRef}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 1444 96"
        preserveAspectRatio="xMidYMid meet"
      >
        <defs>
          <filter id="filter-1">
            <feColorMatrix
              in="SourceGraphic"
              values="0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 1.000000 0"
            ></feColorMatrix>
          </filter>
          <linearGradient
            id="linearGradient-2"
            x1="50%"
            x2="50%"
            y1="0%"
            y2="100%"
          >
            <stop offset="0%" stopOpacity="0.5"></stop>
            <stop offset="100%" stopOpacity="0.5"></stop>
          </linearGradient>
          <path
            id="path-3"
            d="M1.074 53c-.281 0-.554-.108-.76-.31a1.054 1.054 0 01-.268-1.057c.352-1.162 3.506-11.437 5.804-13.711 2.588-2.562 6.799-2.564 9.386 0a6.53 6.53 0 010 9.29C12.94 49.487 2.558 52.61 1.383 52.957a1.191 1.191 0 01-.309.043zm9.47-14.876c-1.15 0-2.3.433-3.176 1.298-1.239 1.229-3.3 6.715-4.662 10.9 4.226-1.348 9.77-3.388 11.01-4.615a4.418 4.418 0 000-6.285 4.496 4.496 0 00-3.172-1.298z"
          ></path>
        </defs>
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g transform="translate(2 -11070)">
            <g transform="translate(0 10567)">
              <g transform="translate(0 503)">
                <g>
                  <path
                    fill="#D70303"
                    stroke="#FFF"
                    strokeLinecap="square"
                    strokeWidth="3"
                    d="M1.05200454e-12 51L461.601239 51 1440 53"
                  ></path>
                  <g ref={rocketRef}>
                    <ellipse
                      cx="1234.5"
                      cy="48"
                      fill="#FFF"
                      rx="48.5"
                      ry="48"
                    ></ellipse>
                    <g filter="url(#filter-1)">
                      <g transform="translate(1207.724 21)">
                        <path
                          fill="#000"
                          fillRule="nonzero"
                          d="M26.534 42c-.285 0-.57-.108-.788-.322L11.441 27.52a1.095 1.095 0 01-.044-1.514L22.118 14.12C29.613 5.44 41.817-.174 52.458.004a1.106 1.106 0 011.086 1.075c.247 10.54-5.49 22.612-14.28 30.045L27.274 41.72c-.21.187-.477.28-.74.28zM13.758 26.698l12.819 12.687L37.8 29.467c8.027-6.79 13.357-17.61 13.522-27.262-9.751.16-20.682 5.437-27.529 13.363l-10.036 11.13z"
                        ></path>
                        <path
                          fill="#FFF"
                          d="M30.702 49.952c-.415-.132-.756-.435-.816-.849l-1.583-10.52c-.09-.6.349-1.155.978-1.243.63-.095 1.213.33 1.306.932l1.236 8.218c4.04-4.603 6.287-10.44 6.287-16.391 0-.607.517-1.099 1.153-1.099.637 0 1.154.492 1.154 1.099 0 7.28-3.096 14.407-8.493 19.547a1.33 1.33 0 01-.892.354 1.12 1.12 0 01-.33-.048zM14.745 25a.928.928 0 01-.157-.011L3.98 23.423a1.115 1.115 0 01-.895-.774c-.13-.411-.024-.865.275-1.169C8.619 16.063 15.801 13 23.142 13c.612 0 1.108.511 1.108 1.141s-.496 1.142-1.108 1.142c-6.029 0-11.944 2.244-16.537 6.22l8.297 1.226c.605.091 1.026.666.94 1.292-.082.568-.556.979-1.097.979z"
                        ></path>
                        <path
                          fill="#000"
                          fillRule="nonzero"
                          d="M37.89 21a5.574 5.574 0 01-3.932-1.609 5.471 5.471 0 010-7.782c2.169-2.145 5.696-2.145 7.865 0a5.471 5.471 0 010 7.782A5.572 5.572 0 0137.891 21zm0-8.799c-.853 0-1.71.322-2.36.964a3.285 3.285 0 000 4.67 3.373 3.373 0 004.72 0 3.285 3.285 0 000-4.67 3.36 3.36 0 00-2.36-.964z"
                        ></path>
                        <g fillRule="nonzero">
                          <use fill="#000" xlinkHref="#path-3"></use>
                          <use
                            fill="url(#linearGradient-2)"
                            xlinkHref="#path-3"
                          ></use>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <circle
                  ref={circleRef}
                  cx="1022"
                  cy="53"
                  r="25"
                  fill="#000"
                  stroke="#FFF"
                  strokeLinecap="square"
                  strokeWidth="3"
                ></circle>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}

export default RocketLine;
import React, { useRef, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { TweenLite, gsap } from "gsap"
import { ScrollTrigger } from 'gsap/src/ScrollTrigger'

const Laurels = ({ style }) => {
  const data = useStaticQuery(graphql`
    query {
        laurels: file(relativePath: { eq: "laurels.png" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `)

  return <Img style={style}
    fluid={data.laurels.childImageSharp.fluid} alt="Laurels"
  />
}

const Animation = ({ text }) => {
  const ref = useRef(null);
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    TweenLite.from(ref.current, {
      scrollTrigger: {
        trigger: ref.current,
        start: '0px, 50%',
        toggleActions: "restart none none reverse"
      },
      autoAlpha: 0,
      duration: 1
    });
  })

  return (
    <div>
      <div ref={ref}>
        <Laurels style={{ width: `20rem`, margin: `auto` }} />
      </div>
      <p style={{ textAlign: `center`, marginTop: `-12rem` }}>
        {text}
      </p>
    </div>
  )
}

export default Animation
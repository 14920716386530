import React, { useRef, useEffect } from "react"
import { gsap } from "gsap"
import { ScrollTrigger } from 'gsap/src/ScrollTrigger'

const Clouds = ({ children }) => {
  const containerRef = useRef(null);
  const tinycloudRef = useRef(null);
  const smallcloudRef = useRef(null);
  const hugecloudRef = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: containerRef.current,
        start: "0px, 90%",
        toggleActions: "restart none none reverse"
      }
    })
      tl.from(smallcloudRef.current, {
        duration: 0.5,
        autoAlpha: 0
      }, "<1")

      tl.from(tinycloudRef.current, {
        duration: 1,
        autoAlpha: 0
      }, "<1")

      tl.from(hugecloudRef.current, {
        duration: 1.5,
        autoAlpha: 0
      }, "<1")

  })

  return (
    <div style={{ width: `100vw` }}>
      <div ref={containerRef} style={{ position: `absolute`, width: `70%`, right: `10vw`}}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1016 606"
          preserveAspectRatio="xMidYMid meet"
        >
          <g
            fill="none"
            fillRule="evenodd"
            stroke="none"
            strokeWidth="1"
            opacity="0.31"
          >
            <g fill="#FFF" fillRule="nonzero" transform="translate(-184 -19903)">
              <g transform="translate(173.71 19903.339)">
                <g transform="translate(11)">
                  <g ref={hugecloudRef} transform="translate(264)">
                    <path
                      d="M462.424 437.31l-360.801.69C38.102 438 0 380.004 0 335.487s25.614-104.814 89.135-104.814c8.929 0 44.292 6.164 52.942 8.206-4.108-15.448-12.827-46.831-10.383-62.879C147.12 74.684 189.027 11 294.53 1.5c40.375-3.636 78.542 4.25 111.064 28.55C434.32 51.512 461.536 85.594 473.99 119 564.064 123.151 632 190.272 632 281.186c0 93.567-75.831 156.123-169.576 156.123zM99.196 245.362c-48.425 0-87.823 39.323-87.823 87.657 0 48.335 39.398 87.658 87.823 87.658h374.795c78.651 0 142.637-63.866 142.637-142.37 0-78.445-63.895-142.275-142.465-142.37l-.157.012c-.579.042-1.16.076-1.745.083h-.155a13.683 13.683 0 01-13.07-9.613c-9.876-31.79-30.101-60.345-56.951-80.405-27.75-20.733-60.796-31.691-95.568-31.691-88.036 0-159.658 71.488-159.658 159.359 0 22.502 4.736 44.43 14.072 65.172a13.642 13.642 0 01-3.229 15.662 13.712 13.712 0 01-15.909 1.877 87.962 87.962 0 00-42.597-11.031z"
                      opacity="0.493"
                    ></path>
                  </g>
                  <g ref={tinycloudRef} transform="translate(896 340)">
                    <path
                      d="M87.07 82.869L19.135 83C7.175 83 0 71.993 0 63.545c0-8.449 4.823-19.892 16.783-19.892 1.682 0 8.34 1.17 9.969 1.557-.774-2.931-2.415-8.887-1.955-11.933C27.7 14.049 35.592 1.963 55.457.16c7.603-.69 14.79.806 20.913 5.418C81.779 9.651 86.903 16.12 89.248 22.46 106.208 23.247 119 35.985 119 53.24c0 17.758-14.278 29.63-31.93 29.63zM18.678 46.44c-9.118 0-16.536 7.463-16.536 16.636 0 9.173 7.418 16.636 16.536 16.636h70.57c14.81 0 26.858-12.121 26.858-27.02 0-14.887-12.031-27.001-26.825-27.019l-.03.002a5.442 5.442 0 01-.328.016h-.03a2.579 2.579 0 01-2.46-1.825c-1.86-6.033-5.668-11.452-10.724-15.259-5.225-3.935-11.447-6.014-17.995-6.014-16.576 0-30.062 13.567-30.062 30.243 0 4.27.892 8.432 2.65 12.369a2.603 2.603 0 01-.608 2.972 2.566 2.566 0 01-2.996.356 16.46 16.46 0 00-8.02-2.093z"
                      opacity="0.493"
                    ></path>
                  </g>
                  <g ref={smallcloudRef} transform="translate(0 449)">
                    <path
                      d="M163.897 155.754L36.018 156C13.504 156 0 135.313 0 119.434c0-15.88 9.078-37.388 31.592-37.388 3.165 0 15.699 2.2 18.764 2.928-1.455-5.51-4.546-16.705-3.68-22.43C52.144 26.406 66.997 3.69 104.39.3c14.31-1.296 27.838 1.516 39.365 10.184 10.182 7.656 19.827 19.813 24.242 31.729C199.92 43.693 224 67.635 224 100.065c0 33.375-26.877 55.689-60.103 55.689zM35.158 87.286c-17.163 0-31.127 14.027-31.127 31.268 0 17.24 13.964 31.267 31.127 31.267h132.839c27.876 0 50.555-22.78 50.555-50.783 0-27.982-22.646-50.75-50.494-50.783l-.056.004c-.205.015-.41.027-.618.03h-.055a4.853 4.853 0 01-4.633-3.43c-3.5-11.34-10.669-21.525-20.185-28.68-9.835-7.396-21.548-11.304-33.872-11.304-31.203 0-56.588 25.5-56.588 56.843 0 8.027 1.679 15.849 4.988 23.247a4.888 4.888 0 01-1.144 5.587 4.836 4.836 0 01-5.64.669 31.021 31.021 0 00-15.097-3.935z"
                      opacity="0.493"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
      <div>
        {children}
      </div>
    </div>
  );
}

export default Clouds;

import React from "react"
import { Col, Row } from "react-bootstrap"
import Styled from "styled-components"
import Facebook from "../components/social-icons/facebook"
import Instagram from "../components/social-icons/instagram"
import LinkedIn from "../components/social-icons/linkedin"
import Twitter from "../components/social-icons/twitter"

const Trademark = Styled.div`
margin-top: 4em;
text-align: center;
color: black;
`

const ContactText = Styled.a`
color: black !important;
font-size: 1rem;
`

const Footer = () => (
    <div style={{ backgroundColor: `white`, width: `100vw`, padding: `5vw` }}>
        <Row>
            <Col md="6" sm="12" style={{ margin: `auto` }}>
                <h2 style={{ color: `black` }}>GET IN TOUCH</h2>
                <ContactText href="tel:+1 347 270 8358">+1 347 270 8358</ContactText><br />
                <ContactText href="mailto:talktome@rodrigodealvarenga.com">talktome@rodrigodealvarenga.com</ContactText><br />
                <span style={{ color: `black`, fontWeight: `bold` }}>My Company: </span> 
                <ContactText href="https://www.hag.ventures" target="blank">hag.ventures</ContactText>
            </Col>
            <Col md="6" sm="12" style={{ margin: `auto` }}>
                <Row>
                    <Col><a aria-label="social link" href="https://www.linkedin.com/in/rodrigodealvarenga/" target="blank"><LinkedIn style={{ width: `2em`, margin: `auto` }} /></a></Col>
                    <Col><a aria-label="social link" href="https://www.instagram.com/roddealvarenga/" target="blank"><Instagram style={{ width: `2em`, margin: `auto` }} /></a></Col>
                    <Col><a aria-label="social link" href="https://www.facebook.com/rodrigo.d.alvarenga" target="blank"><Facebook style={{ width: `2em`, margin: `auto` }} /></a></Col>
                    <Col><a aria-label="social link" href="https://twitter.com/roddealvarenga" target="blank"><Twitter style={{ width: `2em`, margin: `auto` }} /></a></Col>
                </Row>
                <Trademark style={{ fontSize: `0.7rem` }}>© {new Date().getFullYear()}, Rodrigo de Alvarenga. All Rights Reserved.</Trademark>
            </Col>
        </Row>
    </div>
)

export default Footer
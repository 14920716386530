import React from "react"

function Quote(props) {
    return (
        <div style={{ paddingLeft: `10vw` }}>
            <svg width="98px" height="122px" viewBox="0 0 98 122" {...props}>
                <g
                    fill="#FFF"
                    stroke="none"
                    strokeWidth={1}
                    fillRule="evenodd"
                    fontFamily="Copperplate"
                    fontSize={200}
                    fontWeight="normal"
                >
                    <text transform="translate(-143 -2350) translate(143 2350)">
                        <tspan x={0} y={153}>
                            {"\u2018"}
                        </tspan>
                    </text>
                    <text transform="translate(-143 -2350) translate(143 2350)">
                        <tspan x={39} y={153}>
                            {"\u2018"}
                        </tspan>
                    </text>
                </g>
            </svg>
        </div>
    )
}

export default Quote

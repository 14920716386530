import React from "react"
import Logo from "./images/logo"
import { Nav, Navbar } from "react-bootstrap"
import Styled from "styled-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-scroll"

const MainMenu = Styled.div`
position: sticky;
top: 0;
z-index: 16;
@media (min-width: 900px){
  padding-top: 1em;
  padding-bottom: 1em;
  background-color: black;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.active{
  font-weight: bold;
}
`

const MobileMenu = Styled.div`
top: 0;
@media (min-width: 900px){
  display: none;
}
.navbar{
  background-color: #bbb;
}
.nav-link{
  margin: auto;
}
.navbar-toggler{
  border: none;
  #collapsed{
    display: block;
  }
  #expanded{
    display: none;
  }
  &.collapsed{
    #collapsed{
      display: none;
    }
    #expanded{
      display: block;
    }
  }
}
`

const MenuItem = Styled.div`
@media(max-width: 900px){
  display: none;
}
a{
  font-size: 1em;
  color: white !important;
  text-decoration: none;
  cursor: pointer;
}
a:hover{
  border-bottom: 1px solid white;
}
`

const Menu = ({ Titles }) => (
  <MainMenu>
    <MobileMenu>
      <Navbar expand="lg">
        <Navbar.Brand href="/">
          <Logo style={{ width: `10vw` }} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <FontAwesomeIcon id="expanded" style={{ color: `#494949` }} icon={faBars} />
          <FontAwesomeIcon id="collapsed" style={{ color: `#494949` }} icon={faTimes} />
        </Navbar.Toggle>
        <Navbar.Collapse>
          <Nav style={{ height: `50vh` }}>
            <Link className="nav-link" activeClass="active" to="section1" spy={true} smooth={true} offset={-200} duration={500}>
              01. {Titles[0]}
            </Link>
            <Link className="nav-link" activeClass="active" to="section2" spy={true} smooth={true} offset={-200} duration={500}>
              02. {Titles[1]}
            </Link>
            <Link className="nav-link" activeClass="active" to="section3" spy={true} smooth={true} offset={-200} duration={500}>
              03. {Titles[2]}
            </Link>
            <Link className="nav-link" activeClass="active" to="section4" spy={true} smooth={true} offset={-200} duration={500}>
              04. {Titles[3]}
            </Link>
            <Link className="nav-link" activeClass="active" to="section5" spy={true} smooth={true} offset={-200} duration={500}>
              05. {Titles[4]}
            </Link>
            <Link className="nav-link" activeClass="active" to="section6" spy={true} smooth={true} offset={-200} duration={500}>
              06. {Titles[5]}
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </MobileMenu>
    <MenuItem style={{ paddingRight: `20vw` }}><Logo style={{ width: `4em` }} /></MenuItem>
    <MenuItem>
      <Link activeClass="active" to="section1" spy={true} smooth={true} offset={-200} duration={500}>
        01.<br />{Titles[0]}
      </Link>
    </MenuItem>
    <MenuItem>
      <Link activeClass="active" to="section2" spy={true} smooth={true} offset={-70} duration={500}>
        02.<br />{Titles[1]}
      </Link>
    </MenuItem>
    <MenuItem>
      <Link activeClass="active" to="section3" spy={true} smooth={true} offset={-70} duration={500}>
        03.<br />{Titles[2]}
      </Link>
    </MenuItem>
    <MenuItem>
      <Link activeClass="active" to="section4" spy={true} smooth={true} offset={-70} duration={500}>
        04.<br />{Titles[3]}
      </Link>
    </MenuItem>
    <MenuItem>
      <Link activeClass="active" to="section5" spy={true} smooth={true} offset={-70} duration={500}>
        05.<br />{Titles[4]}
      </Link>
    </MenuItem>
    <MenuItem>
      <Link activeClass="active" to="section6" spy={true} smooth={true} offset={-200} duration={500}>
        06.<br />{Titles[5]}
      </Link>
    </MenuItem>
  </MainMenu>
)

export default Menu

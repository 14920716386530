import React from 'react'
import Lottie from "lottie-react"
import lottieJson from '../../lottie/animation_kirta2ax.json'
import Styled from "styled-components"

const Container = Styled.div`
@media(max-width: 900px){
  margin-left: 50vw;
  margin-top: -40em;
}
@media(min-width: 900px){
  margin-left: 75vw;
  margin-top: -550px;
}
`

const style = {
  width: `10em`,
  position: `absolute`
}

const CurlyLine = () => {
  return (
    <Container>
      <Lottie
        animationData={lottieJson}
        style={style}
      />
    </Container>
  )
}

export default CurlyLine;
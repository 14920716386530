import React, { useRef, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { gsap } from "gsap"
import { ScrollTrigger } from 'gsap/src/ScrollTrigger'
import { Row, Col } from "react-bootstrap"

const Images = ({ reference, text }) => {
  const data = useStaticQuery(graphql`
    query {
        hag: file(relativePath: { eq: "experience/hag.png" }) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        hsbc: file(relativePath: { eq: "experience/hsbc.png" }) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        santander: file(relativePath: { eq: "experience/santander.png" }) {
          childImageSharp {
            fluid(maxWidth: 420) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        safra: file(relativePath: { eq: "experience/safra.png" }) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        dacar: file(relativePath: { eq: "experience/dacar.png" }) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        liq: file(relativePath: { eq: "experience/liq.png" }) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        duopana: file(relativePath: { eq: "experience/duopana.png" }) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        c2i: file(relativePath: { eq: "experience/c2i.png" }) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `)
  return (
    <div ref={reference[0]}>
      <Row className="align-items-center">
        <Col md="3" sm="12" ref={reference[1]}>
          <Img style={{ width: `10em`, margin: `auto` }} fluid={data.hag.childImageSharp.fluid} alt="HAG Ventures Logo" />
        </Col>
        <Col md="3" sm="12" ref={reference[2]}>
          <Img style={{ width: `10em`, margin: `auto` }} fluid={data.hsbc.childImageSharp.fluid} alt="HSBC Logo" />
        </Col>
        <Col md="3" sm="12" ref={reference[3]}>
          <Img style={{ width: `10em`, margin: `auto` }} fluid={data.santander.childImageSharp.fluid} alt="Santander Logo" />
        </Col>
        <Col md="3" sm="12" ref={reference[4]}>
          <Img style={{ width: `10em`, margin: `auto` }} fluid={data.safra.childImageSharp.fluid} alt="Safra bank Logo" />
        </Col>
      </Row>
      <p style={{ paddingTop: `2em` }} ref={reference[5]}><b>{text}</b></p>
      <Row className="align-items-center">
        <Col md="3" sm="12" ref={reference[6]}>
          <Img style={{ width: `10em`, margin: `auto` }} fluid={data.dacar.childImageSharp.fluid} alt="Dacar Logo" />
        </Col>
        <Col md="3" sm="12" ref={reference[7]}>
          <Img style={{ width: `10em`, margin: `auto` }} fluid={data.liq.childImageSharp.fluid} alt="LIQ Logo" />
        </Col>
        <Col md="3" sm="12" ref={reference[8]}>
          <Img style={{ width: `10em`, margin: `auto` }} fluid={data.duopana.childImageSharp.fluid} alt="Duopana Logo" />
        </Col>
        <Col md="3" sm="12" ref={reference[9]}>
          <Img style={{ width: `10em`, margin: `auto` }} fluid={data.c2i.childImageSharp.fluid} alt="Centro Internacional de Inovação Logo" />
        </Col>
      </Row>
    </div>
  )
}

const Experience = ({ text }) => {
  const containerRef = useRef(null);
  const hagRef = useRef(null);
  const hsbcRef = useRef(null);
  const santanderRef = useRef(null);
  const safraRef = useRef(null);
  const textRef = useRef(null);
  const dacarRef = useRef(null);
  const liqRef = useRef(null);
  const duopanaRef = useRef(null);
  const c2iRef = useRef(null);

  useEffect(() => {

    gsap.registerPlugin(ScrollTrigger);

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: containerRef.current,
        start: "0px, 50%",
        toggleActions: "restart none none reverse"
      }
    })
      tl.from(hagRef.current, {
        duration: 0.4,
        autoAlpha: 0
      }, "<")

      tl.from(hsbcRef.current, {
        duration: 0.8,
        autoAlpha: 0
      }, "<")

      tl.from(santanderRef.current, {
        duration: 1.2,
        autoAlpha: 0
      }, "<")

      tl.from(safraRef.current, {
        duration: 1.6,
        autoAlpha: 0
      }, "<")

      tl.from(textRef.current, {
        duration: 2,
        autoAlpha: 0
      }, "<")

      tl.from(dacarRef.current, {
        duration: 2.4,
        autoAlpha: 0
      }, "<")

      tl.from(liqRef.current, {
        duration: 2.8,
        autoAlpha: 0
      }, "<")

      tl.from(duopanaRef.current, {
        duration: 3.2,
        autoAlpha: 0
      }, "<")

      tl.from(c2iRef.current, {
        duration: 3.6,
        autoAlpha: 0
      }, "<")
  })
  return (
    <Images
      reference={[containerRef, hagRef, hsbcRef, santanderRef, safraRef,
        textRef, dacarRef, liqRef, duopanaRef, c2iRef]}
      text={text}
    />
  )
}

export default Experience
import React, { useRef, useEffect } from "react"
import { Row, Col } from "react-bootstrap"
import { gsap } from "gsap"
import { ScrollTrigger } from 'gsap/src/ScrollTrigger'
import Styled from "styled-components"
import BlackSection from "./blacksection"

const Number = Styled(Col)`
font-size: 3rem;
color: black;
`

const Paragraph = Styled.div`
p{
    color: black;
    font-size: 1rem !important;
}
`

const Topics = ({ title, maintext, text1, text2, text3, text4 }) => {
    const containerRef = useRef(null);
    const text1Ref = useRef(null);
    const text2Ref = useRef(null);
    const text3Ref = useRef(null);
    const text4Ref = useRef(null);

    useEffect(() => {

        gsap.registerPlugin(ScrollTrigger);

        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: containerRef.current,
                start: "0px, 50%",
                toggleActions: "restart none none reverse"
            }
        })
            tl.from(text1Ref.current, {
                duration: 0.4,
                autoAlpha: 0
            }, "<1")

            tl.from(text2Ref.current, {
                duration: 0.8,
                autoAlpha: 0
            }, "<1")

            tl.from(text3Ref.current, {
                duration: 1.2,
                autoAlpha: 0
            }, "<1")

            tl.from(text4Ref.current, {
                duration: 1.6,
                autoAlpha: 0
            }, "<1")
    })

    return (
        <div ref={containerRef} style={{ backgroundColor: `white`, width: `100vw` }}>
            <BlackSection title={title}>
                <p style={{ color: `black` }}>
                    {maintext}
                </p>
                <Row style={{ margin: `auto`, width: `100%` }}>
                    <Col ref={text1Ref}>
                        <Row className="align-items-start">
                            <Number>1.</Number>
                            <Col>
                                <Paragraph>
                                    {text1}
                                </Paragraph>
                            </Col>
                        </Row>
                    </Col>
                    <Col ref={text2Ref}>
                        <Row className="align-items-start">
                            <Number>2.</Number>
                            <Col>
                                <Paragraph>
                                    {text2}
                                </Paragraph>
                            </Col>
                        </Row>
                    </Col>
                    <Col ref={text3Ref}>
                        <Row className="align-items-start">
                            <Number>3.</Number>
                            <Col>
                                <Paragraph>
                                    {text3}
                                </Paragraph>
                            </Col>
                        </Row>
                    </Col>
                    <Col ref={text4Ref}>
                        <Row className="align-items-start">
                            <Number>4.</Number>
                            <Col>
                                <Paragraph>
                                    {text4}
                                </Paragraph>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </BlackSection>
        </div>
    )
}

export default Topics
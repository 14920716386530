import React, { useRef, useEffect } from "react"
import Styled from "styled-components"
import { gsap } from "gsap"
import { ScrollTrigger } from 'gsap/src/ScrollTrigger'

const WhitePill = Styled.div`
background-color: white; 
color: black;
margin: 0.5em;
padding-right: 0.5em;
padding-left: 0.5em;
font-size: 1.3rem;
@media(max-width: 600px){
  font-size: 0.9rem;
}
@media(min-width: 600px){
  font-size: 1.3rem;
}
.pills1{
    flex-basis: 25%;
}
.pills2{
    flex-basis: 30%;
}
.pills3{
    flex-basis: 25%;
}
.pills4{
    flex-basis: 30%;
}
`

const PillsContainer = Styled.div`
display: flex;
flex-flow: row wrap;
text-align: center;
@media(max-width: 600px){
  justify-content: center;
}
@media(min-width: 600px){
  justify-content: space-between;
}
`

const WhitePills = ({ text }) => {
    const ref = useRef(null);
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ref.current,
                start: "center center",
                toggleActions: "restart none none reverse"
            }
        })
            tl.from(".pills1", { autoAlpha: 0, duration: 0.5 }, "<")
            tl.from(".pills2", { autoAlpha: 0, duration: 1 }, "<")
            tl.from(".pills3", { autoAlpha: 0, duration: 1.5 }, "<")
            tl.from(".pills4", { autoAlpha: 0, duration: 2 }, "<")
    })
    return (
        <PillsContainer ref={ref}>      
            <WhitePill className="pills1">{text[0]}</WhitePill>
            <WhitePill className="pills1">{text[1]}</WhitePill>
            <WhitePill className="pills1">{text[2]}</WhitePill>
            <WhitePill className="pills1">{text[3]}</WhitePill>
            <WhitePill className="pills2">{text[4]}</WhitePill>
            <WhitePill className="pills2">{text[5]}</WhitePill>
            <WhitePill className="pills2">{text[6]}</WhitePill>
            <WhitePill className="pills3">{text[7]}</WhitePill>
            <WhitePill className="pills3">{text[8]}</WhitePill>
            <WhitePill className="pills3">{text[9]}</WhitePill>
            <WhitePill className="pills3">{text[10]}</WhitePill>
            <WhitePill className="pills4">{text[11]}</WhitePill>
            <WhitePill className="pills4">{text[12]}</WhitePill>
            <WhitePill className="pills4">{text[13]}</WhitePill>
        </PillsContainer>
    )
}

export default WhitePills
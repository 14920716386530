import React, { useRef, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import { TweenLite, gsap } from "gsap"
import { ScrollTrigger } from 'gsap/src/ScrollTrigger'

const Glasses = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
        glasses: file(relativePath: { eq: "glasses.png" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `)

  const ref = useRef(null);
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    TweenLite.to(ref.current, {
        scrollTrigger: {
          trigger: ref.current,
          start: '100px, 50%',
          toggleActions: "restart none none reverse"
        },
        backgroundColor: `transparent`,
        duration: 2
      });
  })

  const imageData = data.glasses.childImageSharp.fluid
  return (
    <BackgroundImage
      Tag="section"
      fluid={imageData}
      style={{
        minHeight: `100vh`,
        width: `100vw`,
        position: `relative`,
      }}
      backgroundColor={`white`}
    >
      <div ref={ref} style={{ backgroundColor: `white`, width: `100%`, minHeight: `100vh` }}>
        {children}
      </div>
    </BackgroundImage>
  )
}

export default Glasses
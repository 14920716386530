import React from "react"
import Styled from "styled-components"

const Content = Styled.div`
padding-top: 2em;
position: relative;
z-index: 0;
@media(min-width: 900px){
    p{
        font-size: 1.3em
    }
}
a{
    color: #ddd;
}
@media(min-width: 900px){
    width: 88%
}
@media(max-width: 900px){
    width: 100%
}
`

const Body = ({ children }) => (
    <Content>
        {children}
    </Content>
)

export default Body
import React from "react"
import BlackName from "./blackname"
import { Row, Col } from "react-bootstrap"

const BlackSection = ({ title, children, customwidth }) => {
    return (
        <div style={customwidth || { width: `100vw` }}>
            <Row style={{ padding: `5vw` }} className="align-items-center">
                <Col sm="12" md="6">
                    <BlackName />
                </Col>
                <Col sm="12" md="6">
                    <p style={{ textAlign: `center`, color: `black` }}>
                        {title}
                    </p>
                </Col>
            </Row>
            <div style={{ paddingRight: `10vw`, paddingLeft: `10vw`, paddingBottom: `5vw` }}>
                {children}
            </div>
        </div>
    )
}

export default BlackSection
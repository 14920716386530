import React from "react"
import Name from "./name"
import { Row, Col } from "react-bootstrap"

const Section = ({ title, children, customwidth }) => {
    return (
        <div style={customwidth || { width: `100vw` }}>
            <Row style={{ padding: `5vw` }} className="align-items-center">
                <Col sm="12" md="6">
                    <Name />
                </Col>
                <Col sm="12" md="6">
                    <p style={{ textAlign: `center` }}>
                        {title}
                    </p>
                </Col>
            </Row>
            <div style={{ paddingRight: `10vw`, paddingLeft: `10vw`, paddingBottom:`5vw` }}>
                {children}
            </div>
        </div>
    )
}

export default Section
import React from "react"
import Styled from "styled-components"

const FirstName = Styled.span`
font-weight: bold;
@media(min-width: 768px){
    font-size: 4.5rem;
}
@media(max-width: 768px){
    font-size: 4rem;
}
`

const LastName = Styled.span`
font-family: Didot;
@media(min-width: 768px){
    font-size: 2.55;
}
@media(max-width: 768px){
    font-size: 2.20rem;
}
`

export default () =>
    <p style={{ textAlign: `center` }}>
        <FirstName>RODRIGO</FirstName> <br />
        <LastName>DE ALVARENGA</LastName>
    </p>
import React from 'react'
import Lottie from "lottie-react"
import lottieJson from '../../lottie/lf30_editor_mgav0dur.json'

const style = {
  width: `100vw`
}

const Cascade = () => {
  return (
    <Lottie
      animationData={lottieJson}
      style={style}
    />
  )
}

export default Cascade;
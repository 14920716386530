import React, { useRef, useEffect } from "react"
import { TweenLite, gsap } from "gsap"
import { ScrollTrigger } from 'gsap/src/ScrollTrigger'

const RodrigoName = ({ children }) => {
    const ref = useRef(null);
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        TweenLite.from(ref.current, {
            scrollTrigger: {
                trigger: ref.current,
                start: '0px, 50%',
                toggleActions: "restart none none reverse"
            },
            autoAlpha: 0,
            duration: 1
        });
    })
    return (
        <div ref={ref}>
            {children}
        </div>
    )
}

export default RodrigoName
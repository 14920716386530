import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import Styled from "styled-components"

const ChildDiv = Styled.div`
justify-content: center;
flex-direction: column;
align-items: center;
display: flex;
position: absolute;
width: 100%;
height: 100%;
text-align: center;
h2{
  font-weight: normal;
  font-size: 1.3rem;
}
`

const CurveLine = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
        curveLine: file(relativePath: { eq: "curve_line.png" }) {
          childImageSharp {
            fluid(maxWidth: 3200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `)
  const imageData = data.curveLine.childImageSharp.fluid
  return (
    <BackgroundImage
      Tag="section"
      fluid={imageData}
      style={{
        minHeight: `100vh`,
        width: `100vw`,
        position: `relative`
      }}
    >
      <ChildDiv>
        <div style={{ backgroundColor: `black` }}>
          {children}
        </div>
      </ChildDiv>
    </BackgroundImage>
  )
}

export default CurveLine
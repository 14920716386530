import React, { useRef, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { TweenLite, gsap } from "gsap"
import { ScrollTrigger } from 'gsap/src/ScrollTrigger'

const QuestionMark = ({ style }) => {
  const data = useStaticQuery(graphql`
    query {
      questionMark: file(relativePath: { eq: "question_mark.png" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `)
  return <Img style={style}
    fluid={data.questionMark.childImageSharp.fluid} alt="Question Mark"
  />
}

const Animation = () => {
  const ref = useRef(null);
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    TweenLite.from(ref.current, {
      scrollTrigger: {
        trigger: ref.current,
        start: '0px, 50%',
        toggleActions: "restart none none reverse"
      },
      autoAlpha: 0,
      duration: 1
    });
  })
  return (
    <div ref={ref}>
      <QuestionMark style={{ width: `15em` }} />
    </div>
  )
}

export default Animation